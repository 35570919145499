import AboutHeader from "../Components/AboutHeader";
import AboutText from "../Components/AboutText";
function About() {
    return ( 
        <>
            <AboutHeader/>
            <AboutText/>
        </>
     );
}

export default About;